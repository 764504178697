import React, { useContext, useState } from "react";
import Snack from "./Snackbar";
const SnackbarContext = React.createContext();

export function useSnackbar() {
  return useContext(SnackbarContext);
}

export function SnackbarProvider({ children }) {
  const [snackState, setSnackState] = useState({});

  return (
    <SnackbarContext.Provider value={setSnackState}>
      <Snack
        status={snackState.status}
        pathname={snackState.pathname}
        pathstate={snackState.pathstate}
        severity={snackState.severity}
        message={snackState.message}
        key={new Date()}
      />
      {children}
    </SnackbarContext.Provider>
  );
}
