import authJwtReducer from "./slices/authJwt";
import userReducer from "./slices/user";

import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import projectReducer from "./slices/projects";

const rootPersistConfig = {
  key: "root",
  storage: storage,
};

const rootReducer = combineReducers({
  authJwt: persistReducer(rootPersistConfig, authJwtReducer),
  user: userReducer,
  projects: projectReducer,
});

export { rootPersistConfig, rootReducer };
