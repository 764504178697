import { login, register } from "../redux/slices/authJwt";
import { useDispatch, useSelector } from "react-redux";

export default function useAuth(method) {
  const dispatch = useDispatch();
  const { user, isLoading, isAuthenticated } = useSelector(
    (state) => state.authJwt
  );

  return {
    method: "jwt",
    user: user,
    isLoading: isLoading,
    isAuthenticated: isAuthenticated,

    register: ({ email, password }) =>
      dispatch(
        register({
          email: email,
          password: password,
        })
      ),

    login: ({ email, password, history }) =>
      dispatch(
        login({
          email: email,
          password: password,
          history: history,
        })
      ),
  };
}
