import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import persistStore from "redux-persist/es/persistStore";
import { rootReducer } from "./rootReducer";

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false,
  }),
});

const persistor = persistStore(store);

export { store, persistor };
