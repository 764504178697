import Joi from "joi";

const config = {
	CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
	OAUTH_URL: process.env.REACT_APP_OAUTH_SERVER_URL,
	CLIENT_URL: process.env.REACT_APP_CLIENT_URL,
	INFOPULSE_URL: process.env.REACT_APP_INFOPULSE_URL,
	IDM_URL: process.env.REACT_APP_IDM_API_URL,
};

const configSchema = Joi.object({
	CLIENT_ID: Joi.string().required(),
	OAUTH_URL: Joi.string().required(),
	CLIENT_URL: Joi.string().required(),
	INFOPULSE_URL: Joi.string().required(),
	IDM_URL: Joi.string().required(),
});

const { error } = configSchema.validate(config);

if (error) {
	console.error("Configuration validation error:", error.details);
} else {
	console.log("Configuration is valid");
}

export default config;
