import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import jwt from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";
import { logoutSuccess } from "../redux/slices/authJwt";
import Snack from "./Snackbar";

const cookies = new Cookies();
const AuthVerify = (props) => {
	const [state, setState] = useState({});
	const dispatch = useDispatch();
	const { token, isAuthenticated } = useSelector((state) => state.authJwt);
	props.history.listen(() => {
		console.log("Path Change");
		// if (!cookies.get("__idm_sec")) {
		// 	dispatch(logoutSuccess());
		// 	localStorage.clear();
		// 	cookies.remove("__idm_sec", {
		// 		domain: process.env.REACT_APP_COOKIE_DOMAIN,
		// 		path: "/",
		// 	});
		// }
		// if (isAuthenticated) {
		// 	const decodedJwt = jwt(token);
		// 	if (decodedJwt.exp * 1000 < Date.now()) {
		// 		props.logOut();
		// 		setState({
		// 			open: true,
		// 			pathname: "",
		// 			severity: "warning",
		// 			message: "Session Time Out !",
		// 		});
		// 	}
		// }
	});

	return (
		<>
			<Snack
				openSnack={state.open}
				pathname={state.pathname}
				severity={state.severity}
				message={state.message}
				key={state.key}
			/>
		</>
	);
};

export default withRouter(AuthVerify);
