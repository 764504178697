function path(root, sublink) {
	return `${root}${sublink}`;
}

const ROOTS = {
	app: "/",
	auth: "/",
};

export const PATH_AUTH = {
	// auth: {
	// 	login: path(ROOTS.auth, "login"),
	// 	// register: path(ROOTS.auth, "register"),
	// 	otp: path(ROOTS.auth, "otp-auth"),
	// 	oauth: path(ROOTS.auth, "oauth"),
	// 	logout: path(ROOTS.auth, "logout"),
	// 	business: path(ROOTS.auth, "add-org"),
	// 	personal: path(ROOTS.auth, "add-bill"),
	// 	edit_org: path(ROOTS.org, "edit_org"),
	// 	user: path(ROOTS.auth, "org-user"),
	// 	payment: path(ROOTS.auth, "payment/:plan"),
	// 	join_org: path(ROOTS.auth, "join-organisation/:invite_id"),
	// 	verify: path(ROOTS.auth, "verify-account/:activation_key"),
	// 	forgotPassword: path(ROOTS.app, "forgot-password"),
	// 	resetPassword: path(
	// 		ROOTS.app,
	// 		"password-reset-confirm/:uid/:token/:org_id?"
	// 	),
	// },
	app: {
		home: path(ROOTS.app, ""),
		rssCreator: path(ROOTS.app, "rss_creator"),
		configModel: path(ROOTS.app, "config-model"),
		projectList: path(ROOTS.app, "project-list"),
		projectDetail: path(ROOTS.app, "project-details/:project_code"),
		allSources: path(ROOTS.app, "all-sources"),
		allCompanies: path(ROOTS.app, "all-companies"),
		viewNews: path(ROOTS.app, "view-news/:project_code"),
		viewNotifications: path(ROOTS.app, "view-notifications/:project_code"),
	},
};
