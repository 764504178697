import React, { useState, useEffect } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Fade from "@material-ui/core/Fade";
import { useHistory } from "react-router";
// import SnackbarContent from '@material-ui/core/SnackbarContent';

function getSeverity(status) {
  if (status >= 400 && status <= 599) {
    return "error"
  } else if (status >= 200 && status <= 299) {
    return "success"
  } else if ((status >= 100 && status <= 199) || (status >= 300 && status <= 399)) {
    return "info"
  }
}

export default function Snack({
  status,
  severity,
  message,
  pathname,
  pathstate,
  vertical = "top",
  horizontal = "center",
}) {
  const history = useHistory();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (severity || status) {
      setOpen(true);
    }
  }, [])

  const handleClose = () => {
    setOpen(false);
    if (pathname) {
      history.push({
        pathname: pathname,
        state: pathstate || null,
      });
    }
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={2000}
        TransitionComponent={Fade}
        onClose={handleClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity={severity || getSeverity(status)}
          style={{
            height: 'auto',
            // lineHeight: '22px',
            // padding: 24,
            whiteSpace: 'pre-line'
          }}
        >
          {status === 422 ? message.map((errorList, index) => `${index}) ${errorList.msg} \n`)
            : (message === undefined ? "Something went wrong.." : message)}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}
