import axiosInstance from "../../utils/axios";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orgProfile: {},
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    getProfileSuccess(state, action) {
      state.orgProfile = action.payload;
      console.log("MODIFed ", state.orgProfile);
    },
  },
});

export default slice.reducer;

export function getOrganizationProfile(memberId) {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get("/api/v1/auth/org/profile/");
      dispatch(slice.actions.getProfileSuccess(response.data));
    } catch (error) {
      console.log(error);
    }
  };
}
