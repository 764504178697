import jwtDecode from "jwt-decode";
import axiosInstance from "../../utils/axios";
import { Cookies } from "react-cookie";
import { createSlice } from "@reduxjs/toolkit";

const cookies = new Cookies();

const initialState = {
  isLoading: false,
  isAuthenticated: false,
  token: null,
  audioFiles: null,
  user: {},
  orgProfile: {},
  stageData: {},
};

const slice = createSlice({
  name: "authJwt",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    getInitialize(state, action) {
      state.isLoading = false;
      state.isAuthenticated = action.payload;
      state.token = action.payload.token;
    },
    loginSuccess(state, action) {
      state.isAuthenticated = true;
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    setAudioFiles(state, action) {
      state.audioFiles = action.payload;
    },
    logoutSuccess(state) {
      state.isAuthenticated = false;
      state.user = null;
      state.token = null;
    },
  },
});

export default slice.reducer;
export const { loginSuccess, logoutSuccess, setAudioFiles } = slice.actions;
export const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

export function register({
  email,
  firstName,
  lastName,
  contact,
  organization,
  agreeConditions,
}) {
  return async (dispatch) => {
    const response = await axiosInstance.post("/auth/register/", {
      request_user_email: email,
      request_user_f_name: firstName,
      request_user_l_name: lastName,
      mobile_number: contact,
      organisation_name: organization,
      is_terms_of_service_agree: agreeConditions,
    });
    return response;
  };
}

export function login({ email, is_register }) {
  return async (dispatch) => {
    const response = await axiosInstance.post("/auth/join/", {
      email: email,
      is_register: is_register,
    });

    console.log("LOGDATA>>>>", response);
    const token = response.data.tokens.access;
    const user = response.data.user_data;
    dispatch(slice.actions.loginSuccess({ user, token }));
  };
}

export function googleSignup({ googleAuthToken }) {
  return async (dispatch) => {
    const response = await axiosInstance.post("/google-oauth/register", {
      token_id: googleAuthToken,
    });
    console.log("GOOGLE_AUTH_RESPONSE>>", response);
    const token = response.data.tokens.access;
    const user = response.data.user_data;
    localStorage.setItem("token", token);

    let expires = new Date();
    expires.setTime(expires.getTime() + 60 * 1000);
    cookies.set("__idm_sec", token, { path: "/", expires });
    axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + token;
    dispatch(slice.actions.loginSuccess({ user, token }));
  };
}
