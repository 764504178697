import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Divider, IconButton, MenuItem, Select } from "@mui/material";
import { Task } from "@mui/icons-material";
import { TagCloud } from "react-tagcloud";
import axiosInstance from "../../utils/axios";
import axiosInstanceV2 from "../../utils/axios-v2";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 900,
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4,
};

export default function CloudChartModal({
	open,
	setOpen,
	keywords,
	company,
	project_code,
	startDate,
	endDate,
	source,
	projectId,
}) {
	const [words, setWords] = React.useState([]);

	const SimpleCloud = () => (
		<TagCloud
			minSize={12}
			maxSize={35}
			tags={words}
			className="peep"
			shuffle={true}
			randomSeed={5}
		/>
	);
	// const [open, setOpen] = React.useState(false);
	const handleClose = () => setOpen(false);

	React.useEffect(() => {
		async function getCloudData() {
			try {
				const { data } = await axiosInstanceV2.get(
					`/api/project/${projectId}/get-news/?num_articles=500&keywords=${keywords}&company=${company}&start_date=${startDate}&end_date=${endDate}&source=${source}`
				);
				var content = data.map((item) => {
					return {
						content: item.content,
					};
				});

				const resp = await axiosInstanceV2.post(
					`/api/project/wordcloud`,
					content
				);
				setWords(resp?.data.slice(0, 50));
			} catch (error) {
				console.log(error.response);
			}
		}

		if (open) getCloudData();
	}, [keywords, open, projectId]);

	return (
		<div>
			{/* <Button onClick={handleOpen}>Open CloudChart Downloader</Button> */}
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<Box mt={2} mb={2} style={{ display: "flex" }}>
						<Typography>Word Cloud</Typography>
					</Box>
					<Divider />
					<SimpleCloud />
					<Divider />
					<Box className="bt_button alltxfield al_right" mt={1}>
						<Button variant="contained" onClick={handleClose}>
							Close
						</Button>
					</Box>
				</Box>
			</Modal>
		</div>
	);
}
